import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Header from '../components/Header'
import Carousel from '../components/Carousel'
import { ProjectContainer, ProjectCaption } from '../components/Carousel/styles'
import { Container } from '../components/Content/styles'

export class ProjectPageTemplate extends Component {
  state = { counter: 0 }

  getNr = nr => (nr + 1 < 10 ? `0${nr + 1}` : nr + 1)

  handleSlideChange = i => {
    this.setState({ counter: i })
  }

  render () {
    const { content } = this.props
    const { counter } = this.state
    const gallery = content.frontmatter.gallery
    return (
      <ProjectContainer>
        <Carousel
          slides={gallery}
          html={content.html}
          handleSlideChange={this.handleSlideChange}
          selectedSlide={counter}
        />
        <ProjectCaption>
          <p>
            {gallery[counter] && gallery[counter].caption
              ? gallery[counter].caption
              : 'Details'}
          </p>
          <p>{`${this.getNr(counter)} | ${this.getNr(gallery.length)}`}</p>
        </ProjectCaption>
      </ProjectContainer>
    )
  }
}

const ProjectPage = ({ data }) => {
  const { project, home } = data

  const last_id = project.frontmatter.project_id
  const id_arr = home.edges[0].node.frontmatter.projects
  const nr = id_arr.map(id => id.project).indexOf(last_id)

  return (
    <Layout pageTitle={project.frontmatter.title}>
      <Header link={`/#gallery-${nr}`} />
      <Container style={{ height: '100vh' }}>
        <ProjectPageTemplate content={project} />
      </Container>
    </Layout>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    project: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        project_id
        title
        gallery {
          caption
          media_file {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          type
          path
        }
      }
    }
    home: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            projects {
              project
            }
          }
        }
      }
    }
  }
`
